/* eslint-disable max-lines-per-function */
// import FileIcon from "../icons/file.svg";
import { File } from "@styled-icons/feather/File";
import { message, open } from "@tauri-apps/api/dialog";
import { readTextFile } from "@tauri-apps/api/fs";
import React, { FC, PropsWithChildren, useRef } from "react";
import { batch, connect, useStore } from "react-redux";
import type { Dispatch } from "redux";
import { ActionCreators } from "redux-undo";
import styled from "styled-components";
import { Alert, Flex, Grid, Input } from "theme-ui";
import { clearCanvas, loadDesign } from "../../actions/global";
import { getCanvas } from "../../design/canvas";
import type { RootState } from "../../lib/configureStore";
import validate from "../../lib/configureStore.validator";
import { EcogardenCanvas } from "../../lib/fabric";
import { loadCanvas } from "../../lib/fabric/canvas";
import { applyLayerFilters } from "../../lib/fabric/layers";
import { handleLoadFile } from "../../lib/files";
import { EcogardenObjects } from "../../lib/objects";
import { isTauri } from "../../lib/tauri";
import InfoModal from "../modals/InfoModal";
import { fitToViewport } from "../tools/FitToViewport";

const Form = styled.form`
	padding: 0.6em;
`;

const Label = styled.label`
	margin: 0;
`;

type OnCloseHandler = {
	readonly onClose: () => void;
};

interface Props {
	readonly canvas?: fabric.Canvas;
	readonly dispatch: Dispatch;
	readonly isOpen: boolean;
}

const DropBox: FC<PropsWithChildren> = ({ children }) => {
	return (
		<Flex
			sx={{
				alignItems: "center",
				justifyContent: "center",

				textAlign: "center",
				cursor: "pointer",

				border: "2pt dashed var(--accent-color)",
			}}
		>
			{children}
		</Flex>
	);
};

// eslint-disable-next-line max-lines-per-function
const LoadDesign: React.FunctionComponent<Readonly<Props & OnCloseHandler>> = ({
	canvas,
	dispatch,
	onClose,
	isOpen,
	...props
}) => {
	const reader = useRef(new FileReader());
	const store = useStore<RootState>();

	const onFinishLoad = async (parsed: unknown): Promise<void> => {
		if (!canvas) {
			return;
		}

		canvas.clear();
		canvas.requestRenderAll();

		// Clear the canvas
		dispatch(clearCanvas());

		// Load the new parsed design
		// eslint-disable-next-line functional/no-try-statement
		try {
			const design = validate(parsed);

			return loadCanvas(canvas, design).then(() => {
				fitToViewport(canvas)(canvas.getObjects().filter(({ id }) => id));
				canvas.requestRenderAll();
				batch(() => {
					dispatch(ActionCreators.clearHistory());
					dispatch(
						loadDesign({
							objects: design.objects as unknown as readonly EcogardenObjects[],
							viewport: design.viewport,
						})
					);
				});
				const layers = store.getState().layers;
				applyLayerFilters(canvas as EcogardenCanvas)(layers);
				document?.querySelector("#loading")?.classList.remove("active");
				onClose();
			});
		} catch (error) {
			console.error("Could not load design", error);
			return Promise.resolve();
		}
	};

	if (!canvas) {
		return <></>;
	}

	return (
		<InfoModal
			title="Load your design"
			isOpen={!!canvas && isOpen}
			onClose={onClose}
			{...props}
		>
			<Alert
				variant="primary"
				mb={2}
				sx={{
					borderRadius: 0,
					justifyContent: "center",
					color: "white",
					fontStyle: "italic",
				}}
			>
				Warning: Loading a design will clear your current design.
			</Alert>

			<Form>
				<Label htmlFor="load-design">
					<DropBox>
						<span>
							<File
								width="24"
								height="24"
								style={{ stroke: "currentColor", margin: "0.3em" }}
							/>
							Find a file or drag the file here.
						</span>
					</DropBox>
				</Label>
				<Input
					onClick={async (e) => {
						if (isTauri()) {
							e.preventDefault();
							const selected = await open({
								filters: [
									{
										name: "Ecogarden Design",
										extensions: ["ecogarden"],
									},
								],
							});

							if (Array.isArray(selected)) {
								// user selected multiple files
							} else if (selected === null) {
								// user cancelled the selection
							} else {
								// user selected a single file
								const readFile = await readTextFile(selected);

								onFinishLoad(JSON.parse(readFile));
							}
						}
					}}
					id="load-design"
					type="file"
					onInput={(e) => {
						document.querySelector("#loading")?.classList.add("active");
						onClose();
						handleLoadFile(reader)(onFinishLoad)(e);
					}}
					accept="plain/text"
					style={{ display: "none" }}
				/>
			</Form>
		</InfoModal>
	);
};

export default connect((state: RootState) => ({
	canvas: getCanvas(state.canvas),
}))(LoadDesign);
