/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import { fabric } from "fabric";
import React, { useEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { batch, useDispatch, useSelector, useStore } from "react-redux";
import { Store } from "redux";
import { Box } from "theme-ui";
import * as canvasActions from "../../actions/canvas";
import { modifiedObject } from "../../actions/objects";
import { getCanvas, setCanvas } from "../../design/canvas";
import {
  objectsReconcilation,
  Reconcilation,
  setupCanvasEvents,
  setupWindowEvents,
  syncCanvasWithObjects,
} from "../../lib/canvas";
import { syncCanvasWithWindow } from "../../lib/canvasEvents";
import type { RootState } from "../../lib/configureStore";
import { EcogardenCanvas } from "../../lib/fabric";
import { loadCanvas } from "../../lib/fabric/canvas";
import {
  applyLayerFilters,
  fillOpacityFilter,
  layerLockVisibleFilter,
} from "../../lib/fabric/layers";
import {
  EcogardenFabricObject,
  EcogardenFabricObjects,
} from "../../lib/fabric/objects";
import { useCanvas } from "../../lib/fabric/useCanvas";
import log from "../../lib/log";
import { EcogardenObjects, toEcogardenObject } from "../../lib/objects";
import profiling, {
  getProfilingLevel,
  profiler,
  Profiling,
} from "../../lib/profiling";
import LabelOverlay from "../objects/LabelOverlay";

const initializeCanvas: (
  store: Store<RootState>
) => (canvasId?: string) => (fabricCanvas: fabric.Canvas) => void =
  (store) =>
  (canvasId) =>
  async (fabricCanvas): Promise<readonly EcogardenFabricObjects[]> => {
    const { viewport, objects } = store.getState();

    log.debug("initializing canvas", canvasId ?? "canvas1");
    setCanvas(canvasId ?? "canvas1", fabricCanvas);

    return loadCanvas(fabricCanvas, { viewport, objects })
      .then((objs) => {
        log.debug("canvas loaded, update state", canvasId);
        setCanvas(canvasId ?? "canvas1", fabricCanvas);

        store.dispatch(canvasActions.setCanvas(canvasId ?? "canvas1"));
        document.querySelector("#loading")?.classList.remove("active");

        if (getProfilingLevel() >= Profiling.Debug) {
          fabricCanvas.on("before:render", () => {
            profiling.start(Profiling.Debug)("render");
          });
          fabricCanvas.on("after:render", () => {
            profiling.stop(Profiling.Debug)("render");
          });
        }

        return objs;
      })
      .catch((error) => {
        console.error("Error loading canvas", error);
        return [];
      });
  };

const syncCanvasOrder =
  (canvas: fabric.Canvas) =>
  (fabricObjects: readonly EcogardenFabricObjects[]) =>
  (objects: readonly EcogardenObjects[]) => {
    const mapping: { readonly [key: string]: EcogardenFabricObject } =
      fabricObjects
        .filter(({ id }) => id !== undefined)
        // eslint-disable-next-line unicorn/prefer-object-from-entries, max-nested-callbacks
        .reduce((acc, obj) => {
          if (!obj.id) {
            return acc;
          }

          // eslint-disable-next-line fp/no-mutation
          acc[obj.id] = obj;

          return acc;
          // eslint-disable-next-line functional/prefer-readonly-type
        }, {} as { [key: string]: EcogardenFabricObject });

    // eslint-disable-next-line unicorn/no-array-for-each, max-nested-callbacks
    objects.forEach((obj, i) => {
      canvas.moveTo(mapping[obj.id], i);
    });
  };

/**
 * TODO
 * On state change, objects or viewport state, we need to sync up the canvas
 */

const ObjectsSync: React.FunctionComponent = () => {
  const { canvas, objects, layers, viewport } = useSelector(
    ({ objects, canvas, layers, viewport }: RootState) => ({
      objects,
      canvas: getCanvas(canvas) as EcogardenCanvas,
      layers,
      viewport,
    })
  );
  const dispatch = useDispatch();

  const [reconEnabled, setReconEnabled] = useState(true);
  const [reconDebug, setReconDebug] = useState(false);
  const [reconcilationCache, setReconcilationCache] = useState<
    Reconcilation | undefined
  >(undefined);

  useHotkeys(
    "shift+r",
    () => {
      if (reconcilationCache) {
        setReconcilationCache(undefined);
      }
      setReconDebug((v) => (v ? false : true));
    },
    [setReconcilationCache, setReconDebug]
  );

  useHotkeys(
    "shift+alt+r",
    () => {
      setReconEnabled((v) => (v ? false : true));
    },
    [setReconEnabled]
  );

  // Sync canvas with state if it changes, like with undo/redo or loading designs
  // Objects
  useEffect(() => {
    if (!canvas) {
      return;
    }

    let reconcilation: Reconcilation | undefined = undefined;
    if (reconEnabled) {
      reconcilation = objectsReconcilation(canvas)(canvas.getObjects())(
        objects.present
      );
    } else {
      reconcilation = { simular: [], removed: [], added: [] };
      log.warning("reconcilation is turned off");
    }

    if (reconDebug) {
      setReconcilationCache(reconcilation);
    }

    syncCanvasWithObjects(canvas)(reconcilation).then((objs) => {
      // Apply layers filters to objects (lock/visibility)
      const profile = profiler(Profiling.Debug);
      const profKey = profile.start("applying layer filters");
      // eslint-disable-next-line max-nested-callbacks
      objs.map((obj) => {
        layerLockVisibleFilter(layers)(obj);
        fillOpacityFilter(layers)(obj);
      });

      syncCanvasOrder(canvas)(canvas.getObjects())(objects.present);

      profile.stop(profKey);
    });
  }, [layers, objects, canvas, reconDebug, reconEnabled]);

  // Zoom
  useEffect(() => {
    if (!canvas) {
      return;
    }

    // eslint-disable-next-line functional/prefer-readonly-type
    canvas.setViewportTransform(viewport as unknown as number[]);
  }, [canvas, viewport]);

  // Layers
  useEffect(() => {
    if (!canvas) {
      return;
    }

    applyLayerFilters(canvas as EcogardenCanvas)(layers);
  }, [canvas, layers]);

  useEffect(() => {
    if (!canvas) {
      return;
    }
    const handleCleared = (
      e: Readonly<{
        readonly deselected?: readonly EcogardenFabricObjects[];
        readonly e: Event;
      }>
    ) => {
      batch(() => {
        // eslint-disable-next-line max-nested-callbacks
        e?.deselected?.forEach((obj) => {
          log.debug("Saving object after deselection", toEcogardenObject(obj));
          // save each object now?
          dispatch(modifiedObject(toEcogardenObject(obj)));
        });
      });
    };
    canvas.on("selection:cleared", handleCleared);
    return function cleanup() {
      canvas.off("selection:cleared", handleCleared);
    };
  }, [canvas, dispatch]);

  if (!reconDebug) {
    return <></>;
  }

  return (
    <Box
      sx={{
        position: "absolute",
        left: 0,
        bottom: 75,
        zIndex: 2,
        pointerEvents: "none",
        fontSize: 0,
        fontFamily: "monospace",
        padding: 2,
        backgroundColor: "accent-bg",
      }}
    >
      {JSON.stringify(reconcilationCache)}
    </Box>
  );
};

// eslint-disable-next-line max-lines-per-function
const Canvas: React.FunctionComponent<{
  readonly canvasId?: string;
  readonly canvas?: fabric.Canvas;
}> = ({ canvasId, canvas }) => {
  const store = useStore<RootState>();
  const [fabricCanvas, elementRef] = useCanvas(
    initializeCanvas(store)(canvasId),
    canvas
  );
  const labelOverlay = useSelector((state: RootState) => {
    return state.features["label-overlay"];
  });

  useEffect(() => {
    if (!fabricCanvas.current) {
      return;
    }

    const removeWindowEvents = setupWindowEvents(fabricCanvas.current);
    const removeCanvasEvents = setupCanvasEvents(store)(fabricCanvas.current);

    syncCanvasWithWindow(fabricCanvas.current);

    return function cleanup() {
      removeWindowEvents();
      removeCanvasEvents();
    };
  }, [store, canvas, fabricCanvas]);

  // Apply canvas to the ecogarden window api
  useEffect(() => {
    if (!fabricCanvas.current) {
      return;
    }

    if (!("ecogarden" in window)) {
      // @ts-ignore
      window.ecogarden = {};
    }
    // @ts-ignore
    window.ecogarden.canvas = fabricCanvas.current;

    return function cleanup() {
      // @ts-ignore
      delete window.ecogarden;
    };
  }, [fabricCanvas]);

  return (
    <>
      <canvas ref={elementRef} id={canvasId} />
      <ObjectsSync />
      {labelOverlay && <LabelOverlay />}
    </>
  );
};

export default Canvas;
