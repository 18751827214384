/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
import { save } from "@tauri-apps/api/dialog";
import {
	FunctionComponent,
	MouseEventHandler,
	PropsWithChildren,
	useState,
} from "react";
import { connect, useStore } from "react-redux";
import { Store } from "redux";
import { getCanvas } from "../../design/canvas";
import type { RootState } from "../../lib/configureStore";
import { generateFilename } from "../../lib/files";
import { generateImage } from "../../lib/fabric/image";
import { isTauri } from "../../lib/tauri";
import SaveImageTauriModal from "../tauri/SaveImageModal";
import SaveImageModal from "./SaveImageModal";

export const download =
	({
		generatedImage,
		name,
	}: {
		readonly generatedImage: string;
		readonly name: string;
	}) =>
	async (): Promise<void> => {
		const a = document.createElement("a");
		a.href = generatedImage;
		a.download = generateFilename("png")(name);

		a.click();
	};

export const handleCanvasImageGeneration =
	(store: Store<RootState>) =>
	(setImage: (image: string) => void) =>
	(): Promise<void> => {
		return generateImage(store.getState().objects.present).then((dataUrl) => {
			if (!dataUrl) {
				return;
			}
			setImage(dataUrl);
		});
	};

type SettingsProps = Pick<RootState, "settings">;

type ClickableButton = React.FunctionComponent<
	React.PropsWithChildren<{
		readonly onClick?: MouseEventHandler<HTMLButtonElement>;
	}>
>;

type Props = {
	readonly ImageButton: ClickableButton;
};

// eslint-disable-next-line max-lines-per-function
const SaveImage: FunctionComponent<
	PropsWithChildren<Props & SettingsProps>
> = ({ ImageButton, settings, children }) => {
	const [showImage, setShowImage] = useState(false);
	const [generatedImage, setGeneratedImage] = useState("");
	const store = useStore<RootState>();

	return (
		<>
			<ImageButton
				onClick={(e) => {
					handleCanvasImageGeneration(store)(setGeneratedImage)().then(() => {
						setShowImage(true);
					});

					setTimeout(() => {
						(e.target as HTMLButtonElement).blur();
					}, 1000);
				}}
			>
				{children}
			</ImageButton>
			{isTauri() ? (
				<SaveImageTauriModal
					onClose={() => setShowImage(false)}
					download={download({ generatedImage, name: settings.name })}
					settings={settings}
					image={generatedImage}
					isOpen={showImage}
				/>
			) : (
				<SaveImageModal
					onClose={() => setShowImage(false)}
					download={download({ generatedImage, name: settings.name })}
					settings={settings}
					image={generatedImage}
					isOpen={showImage}
				/>
			)}
		</>
	);
};

export default connect((state: RootState) => ({
	settings: state.settings,
}))(SaveImage);
