/* eslint-disable max-lines-per-function */
import React from "react";
import { Box, Button, Flex, Grid } from "theme-ui";
import DownloadIcon from "../icons/download.svg";
import ImageIcon from "../icons/image.svg";
import InfoModal, { HandlerProps, InfoProps } from "../modals/InfoModal";
import ExportDesign from "./ExportDesign";
import SaveImage from "./SaveImage";
import TwitterIcon from "../icons/twitter.svg";
import LogoIcon from "../settings/LogoIcon";
import ExportSVG from "./ExportSVG";
import IconBlock from "../buttons/IconBlock";
import { isTauri } from "../../lib/tauri";

const SaveModal: React.FunctionComponent<InfoProps & HandlerProps> = ({
	isOpen,
	onClose,
	onBackRequest,
}) => {
	return (
		<InfoModal
			isOpen={isOpen}
			title={isTauri() ? "Save your design" : "Export your design"}
			onClose={onClose}
			onBackRequest={onBackRequest}
		>
			<Grid gap={3} m={3} columns={3}>
				<SaveImage ImageButton={Button}>
					<IconBlock icon={ImageIcon} backIconSx={{ stroke: "accent-bg" }} />{" "}
					Image
				</SaveImage>
				<ExportSVG>
					<IconBlock icon={ImageIcon} backIconSx={{ stroke: "accent-bg" }} />{" "}
					SVG
				</ExportSVG>
				<ExportDesign>
					<IconBlock icon={DownloadIcon} backIconSx={{ stroke: "accent-bg" }} />{" "}
					.design
				</ExportDesign>
			</Grid>
			<Grid sx={{ justifyContent: "center", textAlign: "center" }}>
				<Box>Share your designs with us on social media!</Box>
				<Flex sx={{ justifyItems: "center" }}>
					<a
						target="_blank"
						rel="noreferrer"
						href="https://twitter.com/ecogardendesign"
					>
						<Grid
							sx={{
								gridAutoFlow: "column",
								justifyContent: "flex-start",
								alignItems: "center",
								gridGap: ".3em",
							}}
						>
							<LogoIcon
								aria-label="Logo"
								width="24"
								height="24"
								stroke="darkgreen"
								role="img"
								aria-hidden="true"
							/>
							Ecogarden on <TwitterIcon /> Twitter
						</Grid>
					</a>
				</Flex>
			</Grid>
		</InfoModal>
	);
};

export default SaveModal;
