import Mousetrap from "mousetrap";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Flex, Grid } from "theme-ui";
import { getCanvas } from "../../design/canvas";
import { getMap } from "../../design/maps";
import type { RootState } from "../../lib/configureStore";
import { SelectableCanvas } from "../../lib/controls/mousePan";

// import { getMapbox } from '../../design/mapbox';

const ViewportRow: React.FunctionComponent<{
  readonly name: string;
  readonly description?: string;
  readonly level: number;
  readonly top: number;
  readonly left: number;
}> = ({ name, description, level, top, left, ...props }) => (
  <tr title={description} {...props}>
    <td>{name}</td>
    <td>{level.toFixed(2)}</td>
    <td>{top.toFixed(2)}</td>
    <td>{left.toFixed(2)}</td>
  </tr>
);

type Props = Pick<RootState, "pan" | "zoom" | "objects" | "map"> & {
  readonly canvas?: fabric.Canvas;
};

// eslint-disable-next-line max-lines-per-function, complexity
const Debug: React.FunctionComponent<Props> = ({ canvas, zoom, map, pan }) => {
  const [isActive, setIsActive] = useState(false);

  const mousetrap = useMemo(() => new Mousetrap(), []);
  const mapbox = getMap("map1");
  let mapboxBounds: readonly number[] = [];

  if (mapbox) {
    mapboxBounds = mapbox.getBounds().getNorthWest().toArray();
  }

  useEffect(() => {
    mousetrap.bind("shift+d", () => {
      setIsActive((previous) => (previous ? false : true));
    });

    return function cleanup() {
      mousetrap.unbind("shift+d");
    };
  });

  if (!isActive) {
    return <></>;
  }

  return (
    <Grid
      className="DebugContainer"
      sx={{
        position: "absolute",
        width: "100%",
        height: "100%",
        fontSize: "1rem",
        lineHeight: 1,
        opacity: 0.5,
        alignItems: "flex-end",
        justifyContent: "right",
        pointerEvents: "none",
        fontFamily: "monospace",
        zIndex: 100,
        bottom: "58px",
      }}
    >
      <Flex
        sx={{ bg: "accent-bg", pointerEvents: "all", flexDirection: "column" }}
      >
        <table>
          <tbody>
            {canvas && canvas.width && canvas.height && (
              <ViewportRow
                name="Can Dim"
                description="Canvas dimensions zoom | height | width"
                level={zoom}
                top={canvas.height}
                left={canvas.width}
              />
            )}
            {canvas && canvas.viewportTransform && (
              <ViewportRow
                name="Fabric"
                description="canvas.zoom | canvas.vpCenter.y | canvas.vpCenter.x"
                level={canvas.getZoom()}
                top={canvas.viewportTransform[4]}
                left={canvas.viewportTransform[5]}
              />
            )}
            {map && (
              <ViewportRow
                name="Eco Map"
                description="store.zoom.level store.map.lat store.map.lng"
                level={zoom}
                top={map.lat}
                left={map.lng}
              />
            )}
            <tr>
              <td
                colSpan={4}
                id="mouse-coords"
                title="Mouse x, y, canvas x, y"
                style={{ pointerEvents: "all" }}
              ></td>
            </tr>
            {mapbox && (
              <ViewportRow
                name="Mapbox"
                level={mapbox.getZoom()}
                top={mapboxBounds[0]}
                left={mapboxBounds[1]}
              />
            )}
            <tr>
              <td colSpan={4} id="touch-gestures"></td>
            </tr>
            <tr>
              <td colSpan={4}>
                Pan: {pan ? 1 : 0} sel:
                {(canvas as SelectableCanvas<Event>)?.selectable ? 1 : 0}
              </td>
            </tr>
            <tr>
              <td colSpan={4} id="debug-log"></td>
            </tr>
          </tbody>
        </table>

        <Saving />
      </Flex>
    </Grid>
  );
};

const Saving = () => {
  const [isSaving, setIsSaving] = useState(false);

  if (!isSaving) {
    return <></>;
  }

  return <div>Saving</div>;
};

export default connect((state: RootState) => ({
  map: state.map,
  canvas: getCanvas(state.canvas),
  zoom: state.zoom,
  objects: state.objects,
  pan: state.pan,
}))(Debug);
