/* eslint-disable max-lines-per-function */
import { HelpCircle } from "@styled-icons/feather/HelpCircle";
import { X } from "@styled-icons/feather/X";
import { FieldMetaProps, FormikHandlers } from "formik";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Box, Grid, Paragraph, IconButton as SimpleIconButton } from "theme-ui";
import { getCanvas } from "../../design/canvas";
import { RootState } from "../../lib/configureStore";
import { EcogardenCanvas } from "../../lib/fabric";
import { convertFillColor, findObject } from "../../lib/fabric/objects";
import IconButton from "../buttons/IconButton";
import MenuHeader from "../MenuHeader";
import InfoModal from "../modals/InfoModal";

const Fill: React.FunctionComponent<
  { readonly value?: string; readonly id: string } & FieldMetaProps<
    string | undefined
  > &
    Pick<FormikHandlers, "handleChange" | "handleBlur">
> = ({ id, value, handleChange, handleBlur }) => {
  const canvas = useSelector(
    (state: RootState) => getCanvas(state.canvas) as EcogardenCanvas | undefined
  );
  const [accentColorHelp, setAccentColorHelp] = useState(false);
  const object = findObject(canvas?.getObjects())(id);
  return (
    <Grid
      sx={{
        gridTemplateColumns: "1fr 44px 44px",
      }}
    >
      <label
        sx={{
          display: "grid",
          alignItems: "center",
          gridTemplateColumns: "44px 0.61fr",
          lineHeight: 1,
          gridGap: 3,
          "&:hover,&:focus": {
            backgroundColor: "accent-bg",
            cursor: "pointer",
          },
        }}
      >
        <input
          sx={{
            padding: 0,
            height: 36,
            width: "100%",
            marginRight: 2,
            border: 0,
            cursor: "pointer",
          }}
          type="color"
          name="fill"
          value={value}
          onChange={(e) => {
            if (object) {
              convertFillColor(e.target.value)(object);
              canvas?.requestRenderAll();
            }
            handleChange(e);
          }}
          onBlur={handleBlur}
        />{" "}
        Accent Color
      </label>
      <IconButton
        onClick={(e) => {
          if (object) {
            convertFillColor("green")(object);
            canvas?.requestRenderAll();
          }
          handleChange({ target: { value: "" } });
          (e.target as HTMLButtonElement).blur();
        }}
        icon={X}
        label="Clear accent color"
      />
      <SimpleIconButton
        variant="simple"
        type="button"
        onClick={(e) => {
          setAccentColorHelp(true);
          (e.target as HTMLButtonElement).blur();
        }}
        aria-label="What is Accent Color?"
      >
        <HelpCircle strokeWidth={2} width={24} height={24} />
      </SimpleIconButton>
      <InfoModal
        isOpen={accentColorHelp}
        onClose={() => setAccentColorHelp(false)}
      >
        <MenuHeader onCloseRequest={() => setAccentColorHelp(false)}>
          What is accent color?
        </MenuHeader>
        <Box sx={{ padding: 3 }}>
          <Paragraph>
            Accent color helps you to diferenciate your plants visually.
            Commonly used to showcase plant bloom color and for organizing
            multiple plants in groups.
          </Paragraph>
        </Box>
      </InfoModal>
    </Grid>
  );
};

export default Fill;
