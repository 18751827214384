import { useSelector } from "react-redux";
import { Box, Button, Grid, Label } from "theme-ui";
import { RootState } from "../../lib/configureStore";
import { PIXELS_PER_FEET } from "../../lib/objects";

export const findSizeToScaleByRatio =
  (targetSize: number) =>
  (size: number) =>
  (scale: number): number => {
    return (targetSize / size) * scale;
  };

const ScaleLegend = () => {
  const scale = useSelector((state: RootState) => {
    return state.viewport[0];
  });

  const size = PIXELS_PER_FEET;

  // const oneFootToPixels = size * scale;

  size * scale;

  return (
    <div
      sx={{
        gridArea: "scale",
        height: "1.2em",
        lineHeight: 1,
        backgroundColor: "background",
      }}
    >
      <button
        sx={{
          padding: 0,
          border: "none",
          borderRadius: "none",
          backgroundColor: "transparent",
          margin: "none",
          fontSize: 0,
          width: "100%",
        }}
      >
        <Grid
          sx={{
            gridAutoFlow: "column",
            alignItems: "flex-end",
            justifyContent: "flex-end",
            gridGap: 3,
            paddingX: 2,
          }}
        >
          <Label sx={{ lineHeight: 1 }}>
            {(60 / (size * scale)).toFixed(1)}'
          </Label>
          <Box
            sx={{ border: "2px solid grey", borderTop: "none", height: "60%" }}
            style={{ width: (60 / (scale * size)) * scale * size }}
          ></Box>
        </Grid>
      </button>
    </div>
  );
};

export default ScaleLegend;
