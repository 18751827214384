/** @jsxImportSource theme-ui */
import styled from "@emotion/styled";
import { Download } from "@styled-icons/feather/Download";
import { save } from "@tauri-apps/api/dialog";
import { writeBinaryFile, writeTextFile } from "@tauri-apps/api/fs";
import { documentDir, downloadDir } from "@tauri-apps/api/path";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Grid } from "theme-ui";
import { toJSON } from "../../lib/canvas";
import type { RootState } from "../../lib/configureStore";
import { generateFilename } from "../../lib/files";
import { isTauri } from "../../lib/tauri";
import { dataToUrl } from "../../lib/utils";
import Button from "../buttons/Button";
import IconBlock from "../buttons/IconBlock";
import InfoModal from "../modals/InfoModal";

const Pre = styled("pre")`
	padding: 8px;
	max-width: 100%;
	min-width: 18em;
	max-height: 18em;
	margin-block: 0;
	overflow: auto;
	height: 100%;
	width: 100%;
	background-color: var(--accent-bg-color);
	user-select: all;
`;

// eslint-disable-next-line max-lines-per-function
const ExportDesign: React.FunctionComponent<React.PropsWithChildren<{}>> = ({
	children,
}) => {
	const [design, setDesign] = useState<string | undefined>(undefined);

	const state = useSelector((state: RootState) => state);
	const toUrl = dataToUrl({ type: "application/ecogarden-design" });

	const onClick = (): void => {
		if (!state.settings) {
			return;
		}

		setDesign(toJSON(state));
	};

	// eslint-disable-next-line max-lines-per-function
	const download = async (): Promise<void> => {
		if (!design) {
			console.log("Invalid ecogarden file.");
			return;
		}

		if (isTauri()) {
			// check if the file exists
			const filePath = await save({
				defaultPath:
					(await documentDir()) +
					"/" +
					generateFilename("ecogarden")(state.settings.name),
				filters: [
					{
						name: "Ecogarden Designs",
						extensions: ["ecogarden"],
					},
				],
			});

			if (!filePath) {
				return;
			}

			await writeTextFile(filePath, design);
			toast.success("Saved design", {
				position: toast.POSITION.BOTTOM_LEFT,
			});
		} else {
			const a = document.createElement("a");
			a.href = toUrl([design]);
			a.download = encodeURIComponent(
				generateFilename("ecogarden")(state.settings.name)
			);

			a.click();
		}
	};
	return (
		<>
			<Button onClick={onClick}>{children}</Button>
			<InfoModal
				isOpen={!!design}
				title="Your Design"
				onClose={() => {
					setDesign(undefined);
				}}
			>
				<Grid
					p={2}
					sx={{
						width: "30em",

						gridTemplateColumns: ["1fr ", "1fr 1fr 1fr", "1fr"],
						gridTemplateRows: ["100px 1fr", "1fr ", "175px 1fr", "320px 1fr"],
						alignItems: "center",
						justifyContent: "center",
						maxHeight: ["240px", "320px", "480px"],
						maxWidth: ["320px", "640px", "640px"],
					}}
				>
					<Pre sx={{ whiteSpace: "pre-wrap" }}>
						<code>{design}</code>
					</Pre>
					<Grid sx={{ alignSelf: "center", justifyContent: "center" }}>
						<Button onClick={download}>
							<IconBlock icon={Download} />{" "}
							{isTauri()
								? "Save Ecogarden Design file"
								: "Download Ecogarden Design file"}
						</Button>
					</Grid>
				</Grid>
			</InfoModal>
		</>
	);
};

export default ExportDesign;
