import { FunctionComponent } from "react";
import { Box, Button, Grid } from "theme-ui";
import { generateFilename } from "../../lib/files";
import IconBlock from "../buttons/IconBlock";
import InfoModal from "../modals/InfoModal";
import { Image as ImageIcon } from "@styled-icons/feather/Image";

type SaveImageProps = {
	readonly settings: { readonly name: string };
	readonly isOpen: boolean;
	readonly image: string;
};

type SaveImageHandlerProps = {
	readonly onClose: () => void;
	readonly download: () => void;
};

const SaveImageModal: FunctionComponent<
	SaveImageProps & SaveImageHandlerProps
> =
	// eslint-disable-next-line max-lines-per-function
	({ isOpen, onClose, download, settings, image }) => {
		return (
			<InfoModal isOpen={isOpen} title="Your Design" onClose={onClose}>
				<Grid
					p={2}
					sx={{
						gridTemplateColumns: ["1fr ", "1fr 1fr 1fr", "1fr"],
						gridTemplateRows: [
							"100px .5fr 1fr",
							"1fr ",
							"175px .5fr 1fr",
							"320px .5fr 1fr",
						],
						alignItems: "center",
						justifyContent: "center",
						maxHeight: ["240px", "320px", "480px"],
						maxWidth: ["320px", "640px", "640px"],
					}}
				>
					<img
						src={image}
						alt="Ecogarden Design as PNG"
						style={{
							objectFit: "scale-down",
							maxHeight: "100%",
							maxWidth: "100%",
							justifySelf: "center",
						}}
					/>
					<Box sx={{ margin: "0 auto" }}>
						<em>{generateFilename("png")(settings.name)}</em>
					</Box>
					<Box sx={{ margin: "0 auto" }}>
						<Button onClick={download}>
							<IconBlock icon={ImageIcon} backIconSx={{ stroke: "#666" }} />
							Save Image
						</Button>
					</Box>
				</Grid>
			</InfoModal>
		);
	};

export default SaveImageModal;
