/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { useEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useSelector } from "react-redux";
import { Box, Button, Grid, Heading } from "theme-ui";
import IconButton from "../buttons/IconButton";
import { getCanvas } from "../../design/canvas";
import { calcPolygonArea } from "../../lib/boundaries";
import { findObject, pixelsToFeet } from "../../lib/objects";
import {
  findShapeLayer,
  getFillFile,
  findShapeDetail,
  getShapeSVGFile,
  isCustomShape,
} from "../../shapes";
import { Eye } from "@styled-icons/feather/Eye";

const leftTopFromBoundary = (object) => {
  return { left: object.left, top: object.top };
};

const ObjectsDebug = () => {
  const [isEnabled, setIsEnabled] = useState(false);
  const [isObjectsListEnabled, setIsObjectsListEnabled] = useState(true);
  const [usingPointerEvents, setUsingPointerEvents] = useState(true);
  const [selection, setSelection] = useState([]);
  const [canvas, ecogardenObjects] = useSelector((state) => [
    getCanvas(state.canvas),
    state.objects.present,
  ]);
  useHotkeys("shift+0", () => {
    setIsEnabled((previous) => (previous ? false : true));
    // setIsObjectsListEnabled(() => isEnabled ? false : true);
  });

  useEffect(() => {
    if (!canvas) {
      return;
    }

    const onObjectModified = () => {
      // setFabricObjects(canvas.getObjects());
      setSelection(canvas.getActiveObjects());
    };

    const onSelectionCreated = () => {
      setSelection(canvas.getActiveObjects());
    };

    const onSelectionUpdated = () => {
      setSelection(canvas.getActiveObjects());
    };
    const onSelectionCleared = () => {
      setSelection([]);
    };

    canvas.on("object:modified", onObjectModified);
    canvas.on("selection:created", onSelectionCreated);
    canvas.on("selection:updated", onSelectionUpdated);

    canvas.on("selection:cleared", onSelectionCleared);

    return function cleanup() {
      canvas.off("object:modified", onObjectModified);
      canvas.off("selection:created", onSelectionCreated);
      canvas.off("selection:updated", onSelectionUpdated);

      canvas.off("selection:cleared", onSelectionCleared);
    };
  }, [canvas]);

  if (!isEnabled) {
    return <></>;
  }

  return (
    <>
      <Box
        p={2}
        sx={{
          fontSize: "14px",
          position: "absolute",
          right: 0,
          top: "240px",

          zIndex: 2,
          color: "white",
          backgroundColor: "hsla(170, 0%, 0%, .5)",
          maxHeight: "60vh",
          overflowY: "auto",
          fontFamily: "monospace",
          pointerEvents: usingPointerEvents ? "auto" : "none",
          userSelect: "text",
        }}
      >
        <Grid sx={{ gridTemplateColumns: "1fr 80px" }}>
          <Heading as="h3">Selection</Heading>
          <div sx={{ color: "default" }}>
            <IconButton
              icon={Eye}
              label="Toggle pointer-events"
              onClick={() => setUsingPointerEvents((v) => (v ? false : true))}
              frontIconProps={{
                style: { stroke: usingPointerEvents ? "red" : "currentColor" },
              }}
            />
          </div>
        </Grid>
        {selection.map((object) => {
          const ecogardenObject = findObject(ecogardenObjects)(object.id);

          // if (!ecogardenObject) {
          //   return <div>No ecogarden object found for {object.id}</div>;
          // }

          const { left, top } = leftTopFromBoundary(object);

          return (
            <Grid
              sx={{
                borderBottom: "1px solid hsla(170, 0%, 0%, 0.8)",
                gap: 2,
                gridTemplateColumns: "1fr 1fr",
              }}
              key={object.id}
            >
              <Box sx={{ gridColumn: "1 / 3" }}>
                {isCustomShape(object.subtype) ? (
                  <Box
                    sx={{
                      backgroundImage: `url(${getFillFile(object.subtype)})`,
                      width: "100%",
                      height: 48,
                    }}
                  ></Box>
                ) : (
                  <img
                    src={getShapeSVGFile(object.subtype)}
                    height={64}
                    alt={object.id}
                  />
                )}
              </Box>
              <div>{object.id?.slice(0, 8)}</div>
              <div>
                {ecogardenObject && object.type === ecogardenObject.type
                  ? object.type
                  : `Mismatch types ${object.type} ${ecogardenObject?.type}`}
              </div>
              <div>
                {ecogardenObject && object.subtype === ecogardenObject.subtype
                  ? object.subtype
                  : `Mismatch subtypes ${object.subtype} ${ecogardenObject?.subtype}`}
              </div>
              <div>
                {ecogardenObject && !object.label == !ecogardenObject.label
                  ? object.label
                  : `Mismatch labels ${JSON.stringify(
                      object.label
                    )} ${JSON.stringify(ecogardenObject?.label)}`}
              </div>
              <div>{findShapeLayer(object.subtype)}</div>
              <Grid sx={{ gridTemplateColumns: "auto auto" }} title="Sizes">
                <div>
                  {ecogardenObject && object.width === ecogardenObject.width
                    ? object.width?.toFixed(2)
                    : `Mismatch width ${object.width} ${ecogardenObject?.width}`}
                  px
                </div>
                <div>{pixelsToFeet(object.width).toFixed(2)}&apos;</div>
                <div>
                  {findShapeDetail(object.subtype)?.shape?.includes("circle")}
                </div>
                <div>
                  {ecogardenObject && object.height === ecogardenObject.height
                    ? object.height?.toFixed(2)
                    : `Mismatch height ${object.height} ${ecogardenObject?.height}`}
                  px
                </div>
                <div>{pixelsToFeet(object.height).toFixed(2)}&apos;</div>
              </Grid>
              <div title="Fill">
                Fill:{" "}
                <span style={{ backgroundColor: object.fill }}>
                  <span style={{ color: "white", mixBlendMode: "lighten" }}>
                    {object.fill}
                  </span>
                </span>
              </div>
              <div title="Opacity">Opacity: {object.opacity}</div>
              <div>{object.selectable ? "selectable" : "unselectable"}</div>
              <div>{object.evented ? "evented" : "unevented"}</div>
              <Box title="scaleX">
                <Grid sx={{}}>
                  <div>
                    {ecogardenObject && object.scaleX === ecogardenObject.scaleX
                      ? `scaleX: ${object.scaleX?.toFixed(2)}`
                      : `Mismatch scaleX ${object.scaleX.toFixed(
                          3
                        )} ${ecogardenObject?.scaleX?.toFixed(3)}`}
                  </div>
                  <div title={object.width * object.scaleX}>
                    Width: {(object.width * object.scaleX).toFixed(2)}px
                  </div>
                  <div title={pixelsToFeet(object.width * object.scaleX)}>
                    Width:{" "}
                    {pixelsToFeet(object.width * object.scaleX).toFixed(2)}
                    &apos;
                  </div>
                </Grid>
              </Box>
              <Grid sx={{}}>
                <div title={`ScaleY ${object.scaleY}`}>
                  {ecogardenObject && object.scaleY === ecogardenObject.scaleY
                    ? `scaleY ${object.scaleY?.toFixed(2)}`
                    : `Mismatch scaleY ${object.scaleY.toFixed(
                        3
                      )} ${ecogardenObject?.scaleY?.toFixed(3)}`}
                </div>
                <div title={object.height * object.scaleY}>
                  Height: {(object.height * object.scaleY).toFixed(2)}px
                </div>
                <div title={pixelsToFeet(object.height * object.scaleY)}>
                  Height:{" "}
                  {pixelsToFeet(object.height * object.scaleY).toFixed(2)}
                  &apos;
                </div>
              </Grid>
              <div title="Rotation">
                Rotation:{" "}
                {ecogardenObject && object.angle === ecogardenObject.angle
                  ? object.angle?.toFixed(3)
                  : `Mismatch angle ${object.angle} ${ecogardenObject?.angle}`}
                {}
              </div>
              <Grid sx={{ gridAutoFlow: "column" }} title="bounding rectangle">
                <div title={left}>Left: {left.toFixed(2)}</div>
                <div title={top}>Top: {top.toFixed(2)}</div>
              </Grid>
              <div title="Polygon area">
                {object.type === "polygon"
                  ? `area: ${(calcPolygonArea(object.points) / 25).toFixed(2)}`
                  : null}
              </div>
              <Grid title="Fill color">
                {object.fill ? object.fill : "undefined"}
              </Grid>
              <Grid title="Text size">{object.size ?? ""}</Grid>
              <div title="filler"></div>
              <Grid sx={{ gridAutoFlow: "column", gridColumn: "1 / 3" }}>
                <Button
                  variant="default"
                  onClick={() => console.log("object", object)}
                >
                  Fabric
                </Button>

                <Button
                  variant="default"
                  onClick={() => {
                    if (ecogardenObject) {
                      const o = ecogardenObjects?.find(
                        (o) => o.id === object.id
                      );
                      console.log("object", o);
                    }
                  }}
                >
                  Ecogarden
                </Button>
              </Grid>
            </Grid>
          );
        })}
      </Box>
      {isObjectsListEnabled && (
        <Box
          p={2}
          sx={{
            fontSize: ".8rem",
            position: "absolute",
            left: 0,
            bottom: 58,
            zIndex: 2,
            backgroundColor: "hsla(170, 0%, 0%, .5)",
            maxHeight: "75vh",
            overflowY: "scroll",

            fontFamily: "monospace",
          }}
        >
          <Heading
            as="h3"
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr 44px",
              gridGap: 2,
            }}
          >
            <span>Objects</span>
            <Button
              variant="default"
              onClick={() => {
                console.log("fabric objects", canvas.getObjects());
              }}
            >
              Fabric
            </Button>
            <Button
              variant="default"
              onClick={() => {
                console.log("ecogarden objects", ecogardenObjects);
              }}
            >
              Ecogarden
            </Button>
            <Button
              variant="default"
              onClick={() => {
                setIsObjectsListEnabled((previous) =>
                  previous === true ? false : true
                );
              }}
            >
              X
            </Button>
          </Heading>
          {canvas.getObjects().map((object) => {
            return (
              <Grid
                sx={{
                  cursor: "pointer",
                  borderBottom: "1px solid hsla(170, 0%, 0%, .5)",
                  gridAutoFlow: "column",
                  gridTemplateColumns: "1fr 1fr 1fr 1fr",
                }}
                key={object.id}
                onClick={() => canvas.setActiveObject(object)}
              >
                <div>{object?.id?.slice(0, 8)} </div>
                <div>{object.type} </div>
                <div>{object.subtype}</div>
                <div>
                  {object.width?.toFixed(0)} x {object.height?.toFixed(0)}
                </div>
              </Grid>
            );
          })}
        </Box>
      )}
    </>
  );
};

export default ObjectsDebug;
