/** @jsxImportSource theme-ui */
import styled from "@emotion/styled";
import { save } from "@tauri-apps/api/dialog";
import { writeBinaryFile } from "@tauri-apps/api/fs";
import { pictureDir } from "@tauri-apps/api/path";
import { fabric } from "fabric";
import React, { useState } from "react";
import { connect, useSelector, useStore } from "react-redux";
import { toast } from "react-toastify";
import { Store } from "redux";
import { Box, Grid } from "theme-ui";
import { getCanvas } from "../../design/canvas";
import { constrain, objectsBoundaries } from "../../lib/boundaries";
import type { RootState } from "../../lib/configureStore";
import { createStaticCanvas, loadObjects } from "../../lib/fabric/canvas";
import { generateFilename } from "../../lib/files";
import { toBlob } from "../../lib/image";
import { isTauri } from "../../lib/tauri";
import Button from "../buttons/Button";
import InfoModal from "../modals/InfoModal";

const Container = styled(Grid)`
	@media (min-height: 480) {
		grid-template-columns: 1fr 1fr 1fr;
	}
`;

// eslint-disable-next-line max-lines-per-function
export const generateSVG = async (store: Store<RootState>): Promise<string> => {
	const exportCanvas = createStaticCanvas({})(document.createElement("canvas"));

	// eslint-disable-next-line max-lines-per-function
	return loadObjects(store.getState().objects).then((objs) => {
		objs.forEach((object) => exportCanvas.add(object));

		// const rect = new fabric.Group(
		//   exportCanvas.getObjects()
		// ).getBoundingRect(false, true);

		// console.log('objects rect', rect)

		// Expand export canvas to fit the bounding of all objects
		// const bounding = fitToViewport(exportCanvas)(exportCanvas.getObjects());
		const objects = exportCanvas.getObjects();

		// For SVG we want the design to be exported at 1 scale.
		exportCanvas.setZoom(1);

		const [tlx, tly, brx, bry] = objectsBoundaries(
			objects.filter(({ id }) => id !== undefined)
		) as [number, number, number, number];

		// TODO: make padding configurable
		const padding = 50;
		const box = {
			// Offset the box by the padding
			left: tlx - padding,
			top: tly - padding,
			// We need to account for padding on both sides
			width: brx - tlx + padding * 2,
			height: bry - tly + padding * 2,
		};

		// canvas needs to hold
		const canvasBox = {
			left: 0,
			top: 0,
			width: box.width,
			height: box.height,
		};

		const [x, y, w, h] = constrain(canvasBox)(box);

		exportCanvas.setDimensions({
			width: w,
			height: h,
		});
		exportCanvas.absolutePan({ x, y });
		exportCanvas.renderAll();

		return exportCanvas.toSVG({});
	});
};

const generateSVGUrl = (svg: string): string => {
	return URL.createObjectURL(new Blob([svg], { type: "image/svg+xml" }));
};

interface Props extends Pick<RootState, "settings" | "objects"> {
	readonly canvas?: fabric.Canvas;
}

// eslint-disable-next-line max-lines-per-function
const ExportSVG: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
	children,
	canvas,
	settings,
}) => {
	const [svgUrl, setSVGUrl] = useState<string | undefined>(undefined);
	const store = useStore<RootState>();
	const name = useSelector((state: RootState) => state.settings.name);

	const onClick = (): void => {
		if (!canvas) {
			return;
		}

		generateSVG(store).then((svg) => setSVGUrl(generateSVGUrl(svg)));
	};

	// eslint-disable-next-line max-lines-per-function
	const download = async (): Promise<void> => {
		if (!svgUrl) {
			console.log("Invalid SVG file.");
			return;
		}

		if (isTauri()) {
			// check if the file exists
			const filePath = await save({
				defaultPath: (await pictureDir()) + "/" + generateFilename("svg")(name),
				filters: [
					{
						name: "Scalable Vector Graphics",
						extensions: ["svg"],
					},
				],
			});

			if (!filePath) {
				return;
			}

			toBlob(svgUrl)
				.then((blob) =>
					blob
						.arrayBuffer()
						.then((buf) => writeBinaryFile(filePath, buf))
						.then(() => {
							toast.success("Saved SVG", {
								position: toast.POSITION.BOTTOM_LEFT,
							});
						})
				)
				.catch((e) => console.error(e));
		} else {
			const a = document.createElement("a");
			a.href = svgUrl;
			a.download = encodeURIComponent(generateFilename("svg")(settings.name));
			a.click();
		}
	};

	return (
		<>
			<Button onClick={onClick}>{children}</Button>
			<InfoModal
				isOpen={!!svgUrl}
				title="Your SVG Design"
				onClose={() => {
					setSVGUrl(undefined);
				}}
			>
				<Container
					p={2}
					sx={{
						width: "100%",
						height: "100%",
						gridTemplateColumns: ["1fr ", "1fr 1fr 1fr", "1fr"],
						gridTemplateRows: [
							"100px .5fr 1fr",
							"1fr ",
							"175px .5fr 1fr",
							"320px .5fr 1fr",
						],
						alignItems: "center",
						justifyContent: "center",
						maxHeight: ["240px", "320px", "480px"],
						maxWidth: ["320px", "640px", "640px"],
					}}
				>
					<img
						src={svgUrl}
						alt={generateFilename("svg")(settings.name)}
						sx={{
							width: "100%",
							maxWidth: "100%",
							maxHeight: "100%",
						}}
					/>
					<Box sx={{ textAlign: "center" }}>
						<em>{generateFilename("svg")(settings.name)}</em>
					</Box>

					<Box sx={{ margin: "16px auto" }}>
						<Button onClick={download}>Download SVG</Button>
					</Box>
				</Container>
			</InfoModal>
		</>
	);
};

export default connect((state: RootState) => ({
	canvas: getCanvas(state.canvas),
	settings: state.settings,
	objects: state.objects,
}))(ExportSVG);
